import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@src/utility/api/flightApi';
const initialState = {
    Scenerios: [],
    SceneriosLoading: false,
    error: null,
};

// Define an async thunk to fetch Scenrios
export const fetchScenarios = createAsyncThunk(
    'scenarios/fetchScenarios',
    async () => {
        const response = await api.get(`/lookups/scenarios`);
        return response.data;
    }
);

const ScenariosSlice = createSlice({
    name: 'scenarios',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchScenarios.pending, (state) => {
                state.SceneriosLoading = true;
                state.error = null;
            })
            .addCase(fetchScenarios.fulfilled, (state, action) => {
                state.SceneriosLoading = false;
                state.Scenerios = action.payload;
            })
            .addCase(fetchScenarios.rejected, (state, action) => {
                state.SceneriosLoading = false;
                error = action.error.message;
            });
    },
});

export default ScenariosSlice.reducer;
