import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';

export const flightDetailApi = createApi({
    reducerPath: 'flightDetailApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        flightDetailsByFlightId: build.query({ 
            query: (id) => ({ url: `/approval/flight-detail/${id}`, method: 'get' })
        }),
        editFlightDetail: build.mutation({
            query: ({id, ...body}) => ({ 
                url: `/approval/flight-detail/${id}`,
                method: 'post',
                data: body
            })
        }),
        sendApprovalEmail: build.mutation({
            query: (body) => ({
                url: '/approval/send-approval-email',
                method: 'post',
                data: body
            })
        }),
        setApproveStatus: build.mutation({
            query: (body) => ({
                url: '/approval',
                method: 'post',
                data: body
            })
        })
    }),
});

export const { 
    useFlightDetailsByFlightIdQuery,
    useEditFlightDetailMutation,
    useSendApprovalEmailMutation,
    useSetApproveStatusMutation
} = flightDetailApi;