// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import app from "./app";
import auth from './authentication'
import lookups from './lookups'
import approval from "./approval";
import flightDetails from "./flightDetails"
import scenarios from "./scenarios"
import passengers from './passengers'
import setting from './setting'

import dataTables from '@src/views/tables/data-tables/store'
import { flightApi} from './flightApi';
import { flightDetailApi } from './flightDetailApi';
import { passengerApi } from './passengerApi';
import { lookupApi } from "./lookupApi";
const rootReducer = {
    "flightDetailApi": flightDetailApi.reducer,
    "flightApi": flightApi.reducer,
    "passengerApi": passengerApi.reducer,
    "lookupApi": lookupApi.reducer,
    approval,
    app,
    navbar,
    layout,
    auth,
    dataTables,
    lookups,
    flightDetails,
    scenarios,
    passengers,
    setting
};

export default rootReducer;
