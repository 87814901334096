import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@src/utility/api/flightApi';
const initialState = {
    flightData: null,
    flightLoading: false,
    flightUpdteLoading: false,
    error: null,
};

// Define an async thunk to fetch flight details
export const fetchFlightDetails = createAsyncThunk(
    'flightDetails/fetchFlightDetails',
    async (id) => {
        const response = await api.get(`/approval/get-flight-detail/${id}`);
        return response.data;
    }
);

// Define an async thunk to update flight details
export const updateFlightDetails = createAsyncThunk(
    'flightDetails/updateFlightDetails',
    async (payload) => {
        const response = await api.post(`/approval/flight-detail/${payload.id}`, payload);
        return response.data;
    }
);

const flightDetailsSlice = createSlice({
    name: 'flightDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlightDetails.pending, (state) => {
                state.flightLoading = true;
                state.error = null;
            })
            .addCase(fetchFlightDetails.fulfilled, (state, action) => {
                state.flightLoading = false;
                state.flightData = action.payload;
            })
            .addCase(fetchFlightDetails.rejected, (state, action) => {
                state.flightLoading = false;
                error = action.error.message;
            })
            .addCase(updateFlightDetails.pending, (state) => {
                state.flightUpdteLoading = true;
                state.error = null;
            })
            .addCase(updateFlightDetails.fulfilled, (state, action) => {
                state.flightUpdteLoading = false;
                state.flightData = action.payload;
            })
            .addCase(updateFlightDetails.rejected, (state, action) => {
                state.flightUpdteLoading = false;
                error = action.error.message;
            });
    },
});

export default flightDetailsSlice.reducer;
