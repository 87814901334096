import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';
import { baseUrl } from './flightApi';



export const passengerApi = createApi({
    reducerPath: 'passengerApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            getPassengersByFightId: build.query({ query: (p) => ({ url: '/passenger/flight-passnegers', params: p, method: 'get' }) }),
            sendSmsMessage: build.mutation({
                query: (body) => ({
                    url: '/passenger/send-sms',
                    method: 'post',
                    data: body
                })
            })
        };
    },
});



export const { useGetPassengersByFightIdQuery,useSendSmsMessageMutation} = passengerApi