// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { flightApi } from './flightApi';
import { flightDetailApi } from "./flightDetailApi";
import { passengerApi } from "./passengerApi";
import { lookupApi } from "./lookupApi";


const middlewares = [
  flightApi.middleware,
  flightDetailApi.middleware,
  passengerApi.middleware,
  lookupApi.middleware
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {

    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares);
  },
});
setupListeners(store.dispatch)
export { store };
