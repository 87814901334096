// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-selected-row {
  background: var(--yellow-100) !important;
}

.p-multiselect-panel {
  z-index: 20000 !important;
}

.limit-text {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.actions-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.actions-inline-spacing > * {
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}

.jzrolw {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.overflow-unset {
  overflow-x: unset !important;
  overflow-y: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACE,wCAAA;AAFF;;AAKA;EACE,yBAAA;AAFF;;AAKA;EACE,qBAAA;EACA,YAAA;EACA,mBAAA;EACA,2BAAA;EACA,uBAAA;AAFF;;AAKA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;EACA,mBAAA;AAFF;AAIE;EACE,qBAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,4BAAA;EACA,4BAAA;AAHF;;AAMA;EACE,4BAAA;EACA,4BAAA;AAHF","sourcesContent":["// @import '../node_modules/prismjs/themes/prism-tomorrow';\n// @import './assets/scss/app.scss';\n\n.p-selected-row {\n  background: var(--yellow-100)  !important\n}\n\n.p-multiselect-panel {\n  z-index: 20000 !Important;\n}\n\n.limit-text {\n  display: inline-block;\n  width: 180px;\n  white-space: nowrap;\n  overflow: hidden !important;\n  text-overflow: ellipsis;\n}\n\n.actions-inline-spacing {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n\n  &>* {\n    margin-right: 0.25rem;\n    margin-top: 0.25rem;\n  }\n}\n\n.jzrolw {\n  overflow-x: unset !important;\n  overflow-y: unset !important\n}\n\n.overflow-unset {\n  overflow-x: unset !important;\n  overflow-y: unset !important\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
