import axios from "axios";
// import { toast } from "react-toastify";
// import { toastProbs } from "../store/reduxHelpers";
//first parameter is a function for handleing request  (null)
//second parameter is a function for handleing response
// this function will be called then the code in catch block will executed


// axios.defaults.baseURL=`${import.meta.env.VITE_API_BASE_URL}`
axios.defaults.baseURL=process.env.REACT_APP_API_URL
axios.interceptors.request.use(function (config) {
    // config.headers.differnceInMin =  moment().utcOffset() ;
    // config.headers.differnceInMin = 0;
    return config;

});

axios.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;


    if (!expectedError) {
        console.log("Logging unexpected error :::", error);
        // toast.error("un expected error occrured", toastProbs);
    }
    return Promise.reject(error);
});

export default axios;
