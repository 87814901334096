import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LOCALE, DEFAULT_DIRECTION, getLocaleDirection, DEFAULT_CURRENCY } from "../utility/constants";
import SupportedCurrencies from "../utility/SupportedCurrencies";
import SupportedLocales from "../utility/SupportedLocales";
import storage from '../utility/storage';

const initialState = {
    supportedLocales: SupportedLocales,
    locale: SupportedLocales[DEFAULT_LOCALE],
    direction: DEFAULT_DIRECTION,
    supportedCurrencies: SupportedCurrencies,
    defaultCurrency: DEFAULT_CURRENCY,
    currency: SupportedCurrencies[DEFAULT_CURRENCY],
    priceDisplay: "baseprice", // allowed values: baseprice, fees, prepayamount, totalamount
    lastPing: "",
    isLoadingHotels: false,
    layout: "vertical", // options[String]: "vertical"(default), "horizontal"
    theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
    navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
    footerType: "static", // options[String]: static(default) / sticky / hidden
    disableCustomizer: true, // options[Boolean]: true, false(default)
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
    direction: "ltr", // options[String] : ltr / rtl,
    extraStarred: [],
    suggestions: [],
    starred: [],
    defaultLat: 21.4233733,
    defaultLong: 39.821831,
    defaultZoom: 12.5,
    pages: {},
    flights: {
        flightNo: "",
        from: "",
        to: "",
        date: "",
        sortProperty: "",
        direction: "asc",
        pageNumber: 1,
        pageSize: 10,
    }
};

export const changeLocale = createAsyncThunk(
    "app/changeLocale",
    async (locale) => {
        let _locale = SupportedLocales[locale];
        if (!_locale) _locale = SupportedLocales[DEFAULT_LOCALE];

        storage.setItem("culture", _locale.code);
        storage.setItem("language", _locale.code);
        storage.setItem("cultureCode", _locale.culture);

        window.recaptchaOptions = {
            lang: locale,
        };

        document.getElementsByTagName("html")[0].setAttribute("dir", _locale.direction);

        return _locale;
    }
);

export const changeCurrencySuccess = createAsyncThunk(
    "app/changeCurrency",
    async (currency) => {
        storage.setItem("currency", currency);
        return SupportedCurrencies[currency];
    }
);


export const ping = createAsyncThunk("app/ping", async () => {
    // Implement your ping logic here
    const payload = await fetch("your-ping-endpoint");
    return payload.data;
});


const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setPage: (state, action) => {
            const { key, value } = action.payload;
            if (!state.pages) state.pages = {};
            state.pages[key] = value;
        },
        setFlightFilter: (state, action) => {
            state.flights = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeLocale.pending, (state) => {
                // Add any pending state handling here (optional)
            })
            .addCase(changeLocale.fulfilled, (state, action) => {
                state.locale = action.payload;
                state.direction = action.payload.direction.toLowerCase() === "rtl" ? "rtl" : "ltr";
            })
            .addCase(changeLocale.rejected, (state, action) => {
                // Add any error handling here (optional)
            })
            .addCase(changeCurrencySuccess.pending, (state) => {
                // Add any pending state handling here (optional)
            })
            .addCase(changeCurrencySuccess.fulfilled, (state, action) => {
                state.currency = action.payload;
            })
            .addCase(changeCurrencySuccess.rejected, (state, action) => {
                // Add any error handling here (optional)
            })
            .addCase(ping.pending, (state) => {
                // Add any pending state handling here (optional)
            })
            .addCase(ping.fulfilled, (state, action) => {
                state.lastPing = action.payload;
            })
            .addCase(ping.rejected, (state, action) => {
                // Add any error handling here (optional)
            });
        // .addCase(setIsLoading.type, (state) => {
        //     state.isLoadingHotels = true;
        // })
        // .addCase(clearIsLoading.type, (state) => {
        //     state.isLoadingHotels = false;
        // })
        // ;
    },
});

export const { setPage, setFlightFilter } = appSlice.actions;

export default appSlice.reducer;

