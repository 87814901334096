import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '@src/utility/api/flightApi';

const initialState = {
    flightDetail: {},
    flights: [],
    flights_metadata: {},
    flights_loading: true,
    openDialog: false,
    openDeleteDialog: false,
    brandErrors: [], // Assuming this is for error handling
};


export const getFlightDetails = createAsyncThunk(
    'approval/getFlightDetails',
    async (id) => {
        const response = await api.get(`approval/flight-detail/${id}`);
        return response.data;
    }
);
export const getChanges = createAsyncThunk(
    'approval/getChanges',
    async (payload) => {
        const response = await api.get(`approval/flight-changes`, { params: { ...payload } });
        return response.data;
    }
);
export const resendApprovalEmail = createAsyncThunk(
    'approval/resendApprovalEmail',
    async (payload) => {
        const response = await api.post(`/approval/send-approval-email`, payload);
        return response.data;
    }
);
export const resendNotification = createAsyncThunk(
    'approval/resendNotification',
    async (payload) => {
        const response = await api.post(`/approval/notify-passengers`, payload);
        return response.data;
    }
);

export const editFlightDetail = createAsyncThunk(
    '/approval/flight-detail-update',
    async (payload) => {
        const response = await api.post(`/approval/flight-detail/${payload.id}`, payload);
        return response.data;
    }
)

export const openModal = createAsyncThunk(
    "approval/openModal",
    async (payload) => {
        return payload;
    }
);

const approvalSlice = createSlice({
    name: "approval",
    initialState,
    reducers: {
        setFlightDetails(state, action) {
            const { flightIndex, details } = action.payload;
            state.flightDetail = details; // Update entire flight detail object
            if (flightIndex !== undefined) {
                state.flights[flightIndex].details = details; // Update details in flights array (if applicable)
            }
        },
        showDialog(state, action) {
            state.openDialog = true;
        },
        showDeleteDialog(state, action) {
            state.openDeleteDialog = true;
        },
        resetDialog(state, action) {
            state.openDialog = false;
            state.openDeleteDialog = false;
            state.brandErrors = [];
        },
        setItem(state, action) {
            const { name, value } = action.payload;
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChanges.pending, (state, action) => {
            state.flights_loading = true;
        });
        builder.addCase(getChanges.fulfilled, (state, action) => {
            state.flights = action.payload.items;
            state.flights_metadata = action.payload.metadata;
            state.flights_loading = false;
        });
        builder.addCase(getFlightDetails.fulfilled, (state, action) => {
            state.flightDetail = action.payload;
        });
        builder.addCase(resendApprovalEmail.fulfilled, (state, action) => {
            state.flightDetail = action.payload;
        });
        builder.addCase(resendNotification.fulfilled, (state, action) => {
            state.flightDetail = action.payload;
        });
        builder.addCase(editFlightDetail.fulfilled, (state, action) => {
            state.flightDetail = action.payload;
        })
        builder.addCase(openModal.fulfilled, (state, action) => {
            state.openDialog = action.payload.IsOpen;
            const { successCallBack } = action.meta.arg;
            successCallBack && successCallBack(action.payload);
        })
    },
});

export const {
    setFlightDetails,
    showDialog,
    showDeleteDialog,
    resetDialog,
    setItem,
} = approvalSlice.actions;

export default approvalSlice.reducer;
