import {api} from "@app/layout";

api.defaults.baseURL=process.env.REACT_APP_API_URL
api.interceptors.request.use(function (config) {
    return config;
});

api.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;


    if (!expectedError) {
        console.error("Logging unexpected error :::", error);
        // toast.error("un expected error occrured", toastProbs);
    }
    return Promise.reject(error);
});

export default api;
