// Redux Toolkit imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@src/utility/api/flightApi';

// Define async thunks
export const getPassengersByFlightId = createAsyncThunk(
    'passengers/getPassengersByFlightId',
    async (payload) => {
        const response = await api.get(`/passenger/flight-passnegers`, { params: payload });
        return response.data;
    }
);

export const movePassengers = createAsyncThunk(
    'passengers/movePassengers',
    async (payload) => {
        const response = await api.post('/api/movePassengers', payload);
        return response.data;
    }
);

// Passengers slice
const passengersSlice = createSlice({
    name: 'passengers',
    initialState: {
        selectedPassengers: [],
        flightPassengersLoading: false,
        flightPassengers: [],
        flightPassengersMetaData: {},
        movePassengers: [],
        openDialog: false,
        openDeleteDialog: false,
        movePassengersResult: null,
        // You might need more initial state properties based on your needs
    },
    reducers: {
        selectPassengers(state, action) {
            const passenger = action.payload;
            if (state.selectedPassengers.includes(passenger)) {
                state.selectedPassengers = state.selectedPassengers.filter(e => e !== passenger);
            } else {
                state.selectedPassengers.push(passenger);
            }
        },
        setItem(state, action) {
            const { name, value } = action.payload;
            state[name] = value;
        },
        showDialog(state) {
            state.openDialog = true;
        },
        showDeleteDialog(state) {
            state.openDeleteDialog = true;
        },
        resetDialog(state) {
            state.openDialog = false;
            state.openDeleteDialog = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPassengersByFlightId.pending, (state) => {
                state.flightPassengersLoading = true;
                state.error = null;
            })
            .addCase(getPassengersByFlightId.fulfilled, (state, action) => {
                state.flightPassengersLoading = false;

                state.flightPassengers = action.payload.items;
                state.flightPassengersMetaData = action.payload.metadata;
            })
            .addCase(movePassengers.fulfilled, (state, action) => {
                state.movePassengersResult = action.payload;
            })
        // Handle other async actions similarly
    },
});

export const {
    selectPassengers,
    setItem,
    showDialog,
    showDeleteDialog,
    resetDialog,
} = passengersSlice.actions;

export default passengersSlice.reducer;
