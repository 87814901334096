import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';


export const flightApi = createApi({
    reducerPath: 'flightApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            searchFlights: build.query({ query: (p) => ({ url: '/approval/flight-changes',params:p, method: 'get' }) }),
            mutation: build.mutation({
                query: () => ({ url: '/mutation', method: 'post' }),
            }),
        };
    },
});

export const { useSearchFlightsQuery, useMutationMutation} = flightApi

