import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';


export const lookupApi = createApi({
    reducerPath: 'lookupApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            getScenarios: build.query({
                query: (p) => ({ url: '/lookups', params: p, method: 'get'}),
                keepUnusedDataFor: 300
            }),
        };
    },
});

export const { useGetScenariosQuery } = lookupApi;
